import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Video from "../components/video"

import traction_map from "../assets/images/traction_map.png"

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Innovation | Live Cell Technologies Canada</title>
      <meta name="description" content="Innovation" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Innovation</h2>
          <p>Technical details about our innovations</p>
        </header>

        <section id="content">
          <img src={traction_map} alt="" />
          <h3>What is Contractile Force Screening?</h3>
          <p>
            Contractile Force Screening (CFS) bypasses molecular intermediates
            and other indirect endpoints used in current screening technologies,
            and instead leaps directly to the key physiological endpoint –
            cellular contractility.
          </p>
          <p>
            Formerly, cell contractility could be measured by a benchtop
            technology known as traction force microscopy (TFM), which
            quantifies the forces that cells exert on their surrounding
            environment. The principle is simple: If you place a cell on a
            substrate with a known stiffness, you can measure the deformation
            the cell exacts on that substrate, and then calculate the traction
            forces. While TFM is an extremely informative biophysical metric for
            characterizing changes in cell contractility, its adoption is often
            limited in biological and biomedical studies to collaborations with
            researchers in the physical sciences and engineering. This is due to
            challenges formerly requiring significant expertise and specialized
            workflows.
          </p>
          <Video
            videoSrcURL="https://www.youtube.com/embed/IdD8dzW83gQ?rel=0"
            videoTitle="Bead contraction"
            width="400"
            height="400"
          />

          <p>
            Live Cell Technologies Canada bridges this gap by translating TFM
            from a specialized bench technology into an accessible
            high-throughput methodology called Contractile Force Screening
            (CFS). This is done by providing our customers with the four
            critical customizable elements required for CFS:
          </p>
          <ul>
            <li>
              <h4>Physiological range of stiffness</h4>
              <p>
                We have developed formulations of polydimethylsiloxane (PDMS)
                rubber that are compliant and mechanically tunable, yielding
                Young's elastic moduli from ~1 kPa to 100 kPa, placing it
                perfectly within the range of physiological and pathological
                tissue stiffness.
              </p>
            </li>
            <li>
              <h4>Fiduciary Fluorescent Beads</h4>
              <p>
                We synthesize fluorescent nanoparticle beads and coat them on
                the substrate surface to visualize cell substrate deformations.
                No matter the fluorescent labels used in your experiments, we
                can synthesize beads across the spectrum to fit your imaging
                needs.
              </p>
            </li>
            <li>
              <h4>Protein Coatings</h4>
              <p>
                We recapitulate the adhesion biochemistry by coating our soft
                substrates with custom adhesive proteins according to your cell
                requirements, allowing cells to attach, spread, and generate
                force on our patented soft substrates.
              </p>
            </li>
            <li>
              <h4>Software</h4>
              <p>
                Finally, we provide analysis software allowing you to extract
                key contractility metrics from your cell images. Our software
                dynamically generates and exports data visualizations of these
                metrics against the dimensions that you select – such as reagent
                concentration, treatment duration, or cell phenotype – so you
                can succinctly summarize your insights.
              </p>
            </li>
          </ul>
          <p>
            By packing all of this all together into a multi-well format, we put
            the power of CFS into the hands of the user to explore a number of
            conditions affecting cell force within a single experiment. Visit
            our <a href="/applications">applications page</a> to see how CFS has
            already been applied in a number of academic studies.
          </p>
          <Video
            videoSrcURL="https://www.youtube.com/embed/QRxU_bnxrjo?rel=0"
            videoTitle="Traction Map"
            width="560"
            height="315"
          />
        </section>
      </div>
    </div>
  </Layout>
)

export default LeftSidebar
